import { React, Fragment } from "react";
import { Row, Col, Menu, Button } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "src/context/auth-context";
import { Avatar } from "antd";
import userImg from "../../assets/Avatar2.png";
import { UserOutlined, PoweroffOutlined } from "@ant-design/icons";
import "./Header.scss";
import logo from "../../assets/logo.png";

const Header = (props) => {
  const nav = useNavigate();
  const auth = useAuth();
  const navFunction = () => {
    nav("/");
  };
  const handleLogout = () => {
    auth.logout(navFunction);
  };

  const item1 = [
    {
      label: <NavLink to="/">Construction Sites</NavLink>,
      key: "constructionSites",
    },
    {
      label: <NavLink to="/archive-construction-sites">Archive</NavLink>,
      key: "archive",
    },
  ];

  const item2 = [
    {
      key: "Setting",
      icon: <Avatar src={userImg} />,
      children: [
        {
          label: (
            <NavLink to="/user">
              <Button type="secondary" icon={<UserOutlined />} block>
                My Account
              </Button>
            </NavLink>
          ),
          key: "user",
        },
        {
          label: (
            <Button
              type="primary"
              icon={<PoweroffOutlined />}
              onClick={handleLogout}
              block
              loading={auth.logoutLoader}
            >
              Logout
            </Button>
          ),
          key: "logout",
        },
      ],
    },
  ];

  return (
    <Fragment>
      <Row className="header-wrapper">
        <Col
          xs={{
            span: 4,
            offset: 1,
          }}
          md={{
            span: 4,
          }}
          lg={{
            span: 4,
            offset: 2,
          }}
          xl={{
            span: 2,
            offset: 2,
          }}
        >
          <NavLink to="/">
            <img src={logo} alt="logo" />
          </NavLink>
        </Col>
        {props.isLoggedIn && (
          <Col className="nav-links"
            xs={{
              span: 14,
              offset: 1,
            }}
            md={{
              span: 14,
              offset: 0,
            }}
            lg={{
              span: 10,
              offset: 0,
            }}
            xl={{
              span: 13,
              offset: 0,
            }}
          >
            <Menu
              mode="horizontal"
              defaultSelectedKeys={["home"]}
              items={item1}
            />
          </Col>
        )}
        {props.isLoggedIn && (
          <Col className="profile-wrapper"
            xs={{
              span: 2,
              offset: 1,
            }}
            md={{
              span:2
            }}
            sm={{}}
            lg={{
              span: 2,
              offset: 4,
            }}
            xl={{
              span: 2,
              offset: 4,
            }}
          >
            <Menu mode="horizontal" items={item2}/>
          </Col>
        )}
      </Row>
    </Fragment>
  );
};

export default Header;
