import { createContext, useContext, useState } from "react";
import { message } from "antd";
import { Navigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import axios from "axios";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const log = sessionStorage.getItem("login");
  const [isLoggedIn, setIsLoggedIn] = useState(log ? log : false);
  const [logoutLoader, setLogoutLoader] = useState(false);
  // LOGIN
  const login = () => {
    // console.log("Auth Context Login Function")
    setIsLoggedIn(true);
    setLogoutLoader(false);
    sessionStorage.setItem("login", true);
    message.success("Successfully Logged In");
    <Navigate to={"/"} />;
  };
  if (log) {
    // Password
    var bytes = CryptoJS.AES.decrypt(
      sessionStorage.getItem("cipher"),
      `${process.env.REACT_APP_SECRET_KEY}`
    );
    var pass = bytes.toString(CryptoJS.enc.Utf8);
  }
  // LOGOUT
  const logout = (navFunction) => {
    setLogoutLoader(true);
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(
      sessionStorage.getItem("cipher"),
      `${process.env.REACT_APP_SECRET_KEY}`
    );
    const pass = bytes.toString(CryptoJS.enc.Utf8);

    axios({
      method: "post",
      url: `${
        process.env.REACT_APP_DRUPAL_URL
      }/jsonapi/logout?user=${sessionStorage.getItem("user_id")}`,
      headers: {
        "Content-Type": "application/json",
      },
      auth: {
        username: sessionStorage.getItem("username"),
        password: pass,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setIsLoggedIn(false);
          navFunction();
          sessionStorage.removeItem("username");
          sessionStorage.removeItem("email");
          sessionStorage.removeItem("user_id");
          sessionStorage.removeItem("login");
          sessionStorage.removeItem("cipher");
          message.success("Logged Out!!!");
        } else {
          message.error("Ooops Something Went Wrong !!!");
          setLogoutLoader(false);
        }
      })
      .catch((err) => {
        console.log("Logout ERROR", err);
        message.error("Ooops Something Went Wrong !!!");
        setLogoutLoader(false);
      });
  };

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, login, logout, logoutLoader, pass }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
