import { React, useState, useEffect } from "react";
import { Button, Table, Spin, message, Modal } from "antd";
import { EyeFilled, DeleteFilled} from "@ant-design/icons";
import { useAuth } from "src/context/auth-context";
import {
  Link, useNavigate
  // useParams
} from "react-router-dom";
import jQuery from "jquery";
import "../ConstructionSites.scss";
import axios from "axios";

const Tolboxes = (props) => {
  const auth = useAuth();
  const nav = useNavigate()
  jQuery(document).ready(function () {
    const numberOfRows = jQuery(
      ".toolboxes-info-wrapper tbody.ant-table-tbody tr"
    ).length;
    if (numberOfRows < 10) {
      jQuery(
        ".toolboxes-info-wrapper  ul.ant-pagination.ant-table-pagination.ant-table-pagination-right"
      ).css("display", "none");
    } else {
      jQuery(
        ".toolboxes-info-wrapper  ul.ant-pagination.ant-table-pagination.ant-table-pagination-right"
      ).css("display", "flex");
    }
  });
  const [toolboxesInfo, setToolboxesInfo] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [title, setTitle] = useState("");
  const toolboxesInfoUrl = `${process.env.REACT_APP_DRUPAL_URL}/toolboxes-listing-rest-api/${props.siteID}`;
  const { tableData, columns } = getTableData();
  // const nav = useNavigate();
  //const siteID = useParams();
  const viewToolbox = (title) => {
    axios({
      url: `${process.env.REACT_APP_DRUPAL_URL}/jsonapi/get_toolbox?name=${title}&lang=Nederlands&construction_site=${props.siteID}`,
      method: "get",
      auth: {
        username: sessionStorage.getItem("username"),
        password: auth.pass,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          const base64Response = response.data;
          if (base64Response === "") {
            message.error("File Not Found");
          } else {
            // console.log("b64", b64);
            window.viewPdf(base64Response);
          }
        } else {
          message.error("!Oopss Something Went Wrong");
        }
      })
      .then(() => setOpenView(true))
      .catch((err) => {
        message.error("!Oopss Something Went Wrong");
        console.log("Error", err);
      });
  };

  const handleDeleteClick = (title) => {
    setDeletePopUp(true);
    setTitle(title);
  };
  const confirmDelete = () => {
    axios({
      url: `${process.env.REACT_APP_DRUPAL_URL}/jsonapi/delete_toolbox?name=${title}&construction_site=${props.siteID}`,
      method: "delete",
      auth: {
        username: sessionStorage.getItem("username"),
        password: auth.pass,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          message.success("Deleted Toolbox Succesfully!");
          setDeletePopUp(false);
          // <Navigate to={`/construction-sites-detail/${props.siteID}`} />
          // nav(`/construction-sites-detail/${props.siteID}`);
          nav(0);
        } else {
          message.error("!Oopss Something Went Wrong");
        }
      })
      .catch((err) => {
        message.error("!Oopss Something Went Wrong");
        setDeletePopUp(false);
        console.log("Error", err);
      });
  };
  useEffect(() => {
    axios
      .get(toolboxesInfoUrl)
      .then((toolboxesInfoResponse) => {
        setToolboxesInfo(toolboxesInfoResponse.data);
        setIsLoaded(true);
      })
      .catch((toolboxesInfoError) => {
        console.log(toolboxesInfoError, "toolboxesInfoError");
      });
  }, [toolboxesInfoUrl]);

  return !isLoaded ? (
    <h1>
      <Spin size="large" />
    </h1>
  ) : (
    <div className="toolboxes-info-wrapper">
      <Modal
        title="View PDF"
        centered
        visible={openView}
        onOk={() => setOpenView(false)}
        onCancel={() => setOpenView(false)}
        width="fit-content"
        mask={true}
        okText="Close"
        destroyOnClose="true"
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div id="pdf-container-view"></div>
      </Modal>
      <Modal
        title={`Are you sure you want to delete the toolbox: ${title}?`}
        centered
        visible={deletePopUp}
        onOk={() => confirmDelete()}
        okText="Yes"
        okType="danger"
        cancelText="No"
        mask={true}
        onCancel={() => setDeletePopUp(false)}
        destroyOnClose="true"
      >
        <h5>This is irreversible and cannot be undone.</h5>
      </Modal>
      <div className="toolboxes-info-heading">
        <h4>Toolboxes</h4>
        <Link to={`/add-toolbox/${props.siteID}`}>
          <Button type="primary">+</Button>
        </Link>
      </div>
      <hr />
      <Table dataSource={tableData} columns={columns} />
    </div>
  );

  function getTableData() {
    // console.log("toolboxesInfo",toolboxesInfo)
    const tableData = toolboxesInfo.map((item, index) => {
      const title = item.title;
      const date_from = item.field_date_from;
      return {
        key: index,
        name: title,
        date_from: date_from,
        action_view: (
          <div className="eye-bin-wrapper">
            <span
              onClick={() => viewToolbox(item.title)}
              id={item.nid}
              title={title}
            >
              <EyeFilled className="eye" />
            </span>

            <span
              onClick={() => handleDeleteClick(item.title)}
              id={item.nid}
              title={title}
            >
              <DeleteFilled className="bin" />
            </span>
          </div>
        ),
      };
    });

    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Date From",
        dataIndex: "date_from",
        key: "date_from",
      },
      {
        title: "Actions",
        dataIndex: "action_view",
        key: "action_view",
      },
    ];
    return { tableData, columns };
  }
};

export default Tolboxes;
