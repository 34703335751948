import { React, Fragment, useState, useEffect, useRef } from "react";
import { Col, Row } from "antd";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Upload,
  Select,
  message,
  Spin,
} from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "src/context/auth-context";
import moment from "moment";
import dayjs from "dayjs";
import axios from "axios";
import { InfoCircleOutlined, UploadOutlined } from "@ant-design/icons";
import "./AddToolbox.css";
const fd = new FormData();
let fileList = [];
let languageList = [];

const AddToolbox = () => {
  const auth = useAuth();
  const dateFormat = "DD/MM/YYYY";
  // const [language, setLanguage] = useState([]);
  const [constructionSiteData, setConstructionSiteData] = useState(null);
  // const [isLoaded, setIsLoaded] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const buttonRef = useRef(null);

  const constructionSite = useParams();
  const nav = useNavigate();
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
  };
  // ===================================================FILE UPLOAD==========================================================================
  const handleBeforeUpload = (file) => {
    const isPDF = file.type === "application/pdf" || "application/txt";
    if (!isPDF) {
      message.error(`${file.name} is not PDF or Text file`);
    }

    return isPDF || Upload.LIST_IGNORE;
  };

  const uploadDoc = async (options) => {
    const { onSuccess, file } = options;
    fileList.push(file);
    setTimeout(() => {
      onSuccess("Ok");
      // message.info(`Uploaded Successfully - ${file.name}`)
    }, 100);
  };

  // ========================================================LANGUAGE========================================================================
  const { Option } = Select;
  const handleSelectChange = (e) => {
    languageList.push(e);
  };

  // =========================================================FORM SUBMISSION================================================================
  const onFinish = (values) => {
    setIsSubmitted(true);
    console.log("Success:", values);
    const title = values.administrationName;
    const dateFrom = moment(values.dateFrom._d).format("DD-MM-YYYY");
    const dateUntill =
      values.dateUntill === undefined
        ? ""
        : moment(values.dateUntill._d).format("DD-MM-YYYY");
    const cSite = constructionSite.id;
    const url = `${process.env.REACT_APP_DRUPAL_URL}/jsonapi/toolbox?name=${title}&date_from=${dateFrom}&date_untill=${dateUntill}&site=${cSite}`;
    // console.log("url", url);
    // console.log("Language List", languageList);
    // console.log("Filelist", fileList);
    // Form Data
    if (languageList.length > 1) {
      for (var i = 0; i < languageList.length; i++) {
        fd.append(languageList[i], fileList[i]);
      }
    } else {
      fd.append(languageList[0], fileList[0]);
    }
    // console.log("fd", [...fd]);
    // POST REQUEST
    axios({
      method: "post",
      url: url,
      headers: {
        format: "api_json",
      },
      auth: {
        username: sessionStorage.getItem("username"),
        password: auth.pass,
      },
      data: fd,
    })
      .then((response) => {
        if (response.status === 200) {
          for (var i = 0; i < languageList.length; i++) {
            fd.delete(languageList[i]);
          }
          languageList = [];
          fileList = [];
          message.success("Successfully Posted!");
          nav(`/construction-sites-detail/${constructionSite.id}`);
        } else {
          message.error("Ooops Something Went Wrong!");
          nav("/");
        }
      })
      .catch((toolboxErr) => {
        console.log("toolboxErr", toolboxErr);
        setIsSubmitted(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setIsSubmitted(false);
  };
  const constructionSiteURL = `${process.env.REACT_APP_DRUPAL_URL}/construction-sites-detail-rest-api/${constructionSite.id}`;
  // const languageURL = `${process.env.REACT_APP_DRUPAL_URL}/language?_format=json`;
  // ======================================================USE EFFECT===================================================================
  useEffect(() => {
    buttonRef.current.click();
    axios
      .get(constructionSiteURL)
      .then((res) => {
        setConstructionSiteData(res.data[0].field_admin_title);
        // console.log(constructionSiteData);
      })
      .catch((err) => {
        console.log("Error", err);
      });
    // axios({
    //   method: "get",
    //   url: languageURL,
    //   auth: {
    //     username: `${sessionStorage.getItem("username")}`,
    //     password: auth.pass,
    //   },
    // })
    //   .then((langResponse) => {
    //     setLanguage(langResponse.data.sort());
    //     setIsLoaded(true);
    //   })
    //   .catch((langError) => {
    //     console.log("langError", langError);
    //   });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // =============================================================RETURN=========================================================
  return (
    <Fragment>
      {isSubmitted && <Spin className="isSubmitted" size="large" />}
      <Row className="add-toolbox-wrapper">
        <Col span={20}>
          <h1 className="toolbox-heading">
            Add Toolbox For {constructionSiteData}
          </h1>
        </Col>
        <Col span={20} className="toolbox-column">
          <Form
            name="addToolbox"
            initialValues={{
              remember: true,
            }}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className="form-background-color">
              {/*========================================== FORM ITEM =========================================== */}
              <Form.Item
                label="Administration Name"
                name="administrationName"
                tooltip={{
                  title: "Please Enter Administration Name",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Administration Name",
                  },
                ]}
              >
                <Input placeholder="Administration Name" />
              </Form.Item>
              <Form.Item
                label="Date from:"
                name="dateFrom"
                className="input-date"
                initialValue={moment()}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Date From",
                  },
                ]}
              >
                <DatePicker format={dateFormat} />
              </Form.Item>
              <Form.Item
                label="Date untill:"
                name="dateUntill"
                className="input-date"
              >
                <DatePicker disabledDate={disabledDate} format={dateFormat} />
              </Form.Item>
              <br />
              {/* -----------------------------------------UPLOAD--------------------------------------- */}
              <Form.List name="toolbox_upload">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <div className="upload-lang-wrapper" key={key}>
                        <Form.Item
                          {...restField}
                          name={[name, "fileUpload"]}
                          label="Files:"
                          // name="fileUpload"
                          className="file-language-add-btn file-wrapper"
                          rules={[
                            {
                              required: true,
                              message: "Please Upload PDF",
                            },
                          ]}
                        >
                          <Upload
                            accept=".pdf, .txt"
                            beforeUpload={handleBeforeUpload}
                            customRequest={uploadDoc}
                            // showUploadList={false}
                          >
                            <Button icon={<UploadOutlined />}>
                              Click to Upload
                            </Button>
                          </Upload>
                        </Form.Item>
                        {/*------------------------------------- LANGUAGE -------------------------------------*/}
                        <Form.Item
                          {...restField}
                          name={[name, "language"]}
                          // name="language"
                          label="Language:"
                          className="file-language-add-btn language"
                          rules={[
                            {
                              required: true,
                              message: "Please Select Language",
                            },
                          ]}
                        >
                          <Select
                            style={{
                              width: 200,
                            }}
                            placeholder="N/L"
                            onChange={handleSelectChange}
                          >
                            <Option value="Engels">Engels</Option>
                            <Option value="Frans">Frans</Option>
                            <Option value="Nederlands">Nederlands</Option>
                            <Option value="Pools">Pools</Option>
                            <Option value="Portugees">Portugees</Option>
                            <Option value="Roemeens">Roemeens</Option>
                          </Select>
                        </Form.Item>
                      </div>
                    ))}
                    <br />
                    <Form.Item>
                      <Button
                        ref={buttonRef}
                        type="primary"
                        onClick={() => add()}
                        className="plus-btn"
                      >
                        +
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
            <Button
              type="primary"
              htmlType="submit"
              className="toolbox-save-btn"
              size="large"
            >
              Save toolbox
            </Button>
          </Form>
        </Col>
      </Row>
    </Fragment>
  );
};

export default AddToolbox;
