import { React, Fragment, useState } from "react";
import { Col, Row } from "antd";
import { Button, Form, Input, message, Select, DatePicker } from "antd";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "src/context/auth-context";
import axios from "axios";
import "./AddExternalWorker.css";

const AddExtWorker = () => {
  const auth = useAuth();
  const siteID = useParams();
  const nav = useNavigate();
  const { Option } = Select;
  const dateFormat = "DD/MM/YYYY";
  const [submit, setSubmit] = useState(false);

  // FORM SUBMISSION
  const onFinish = (values) => {
    setSubmit(true);
    const id = siteID.id;
    axios({
      url: `${process.env.REACT_APP_DRUPAL_URL}/jsonapi/add_external_worker?construction_site=${id}`,
      method: "post",
      auth: {
        username: `${sessionStorage.getItem("username")}`,
        password: auth.pass,
      },
      data: {
        id: values.id,
        firstName: values.firstName,
        name: values.lastName,
        companyName: values.company,
        firstDayAtSite: moment(values.dateFrom._d).format(dateFormat),
        preferredLanguage: values.language,
      },
    })
      .then((response) => {
        if (response.status === 201) {
          message.success("Sucessfully Created!!!");
          nav(`/construction-sites-detail/${id}`);
        } else if (response.status === 200) {
          message.info("Worker Already Exists");
          setSubmit(false);
        } else {
          message.error("Ooopss Something Went Wrong!");
          setSubmit(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
        message.error("Ooopss Something Went Wrong!");
        setSubmit(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <Fragment>
      <Row className="add-external-worker-wrapper">
        <Col span={20}>
          <h1 className="worker-heading">Add External Worker</h1>
        </Col>
        <Col span={20} className="middle-color">
          <p className="general-info">General Info</p>
          <Form
            name="addExternalWorker"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="on"
          >
            {/*========================================== FORM ITEM =========================================== */}
            <Form.Item
              label="Id:"
              name="id"
              rules={[{ required: true, message: "Please Enter ID" }]}
            >
              <Input placeholder="INSZ" />
            </Form.Item>

            <Form.Item
              label="First Name:"
              name="firstName"
              rules={[{ required: true, message: "Please Enter First Name" }]}
            >
              <Input placeholder="Naam" />
            </Form.Item>

            <Form.Item
              label="Last Name:"
              name="lastName"
              rules={[{ required: true, message: "Please Enter Last Name" }]}
            >
              <Input placeholder="Voornaam" />
            </Form.Item>
            <Form.Item
              name="language"
              label="Language:"
              className="language"
              rules={[
                {
                  required: true,
                  message: "Please Select Language",
                },
              ]}
            >
              <Select
                style={{
                  width: 522,
                }}
                placeholder="N/L"
              >
                <Option value="Engels">Engels</Option>
                <Option value="Frans">Frans</Option>
                <Option value="Nederlands">Nederlands</Option>
                <Option value="Pools">Pools</Option>
                <Option value="Portugees">Portugees</Option>
                <Option value="Roemeens">Roemeens</Option>
              </Select>
            </Form.Item>

            <Form.Item label="Company Name:" name="company">
              <Input placeholder="Company" />
            </Form.Item>

            <Form.Item
              label="First Day at Site:"
              name="dateFrom"
              className="input-date"
              initialValue={moment()}
              rules={[
                {
                  required: true,
                  message: "Please Enter First Day at Site",
                },
              ]}
            >
              <DatePicker format={dateFormat} />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 22,
                span: 2,
              }}
            >
              <Button loading={submit} block type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Fragment>
  );
};

export default AddExtWorker;
