import { React, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button } from "antd";
import "../WorkerDetailsPage.scss";

const WorkerInfo = (props) => {
  // const [isLoading, setIsLoading] = useState(true);
  const { ISNZ, a1_docs, companies, language, limosa_docs, type } = props.data;

  const uniqueCompanies = companies.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });

  function bas64ToFile() {
    // eslint-disable-next-line array-callback-return
    a1_docs.map(function (item) {
      var base64String = item.file;
      const byteCharacters = window.atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const file = new File([byteArray], item.file_name, {
        type: "application/pdf",
      });
      const fileUrl = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = file.name;
      link.innerHTML = item.file_name + ", ";
      document.querySelector(".a1-doc").appendChild(link);
    });

    // eslint-disable-next-line array-callback-return
    limosa_docs.map(function (item) {
      var base64String = item.file;
      const byteCharacters = window.atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const file = new File([byteArray], item.file_name, {
        type: "application/pdf",
      });
      const fileUrl = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = file.name;
      link.innerHTML = item.file_name + ", ";
      document.querySelector(".limosa-doc").appendChild(link);
    });
  }
  useEffect(() => {
    bas64ToFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const lang = getLanguage();
  return (
    <>
      <Row className="worker-info">
        <Col span={24}>
          <div>
            <Row className="info-btn-wrapper">
              <Col span={12}>
                <h3>General Info</h3>
              </Col>
              <Col span={12} className="edit-btn">
                <Link
                  state={{
                    data: props.data,
                    construction_site_id: props.construction_site_id,
                  }}
                  to={`/edit-worker-details/${props.siteID}`}
                >
                  <Button type="primary">Edit</Button>
                </Link>
              </Col>
            </Row>
            <p>
              <strong>
                <span>INSZ</span>:
              </strong>
              <span> {ISNZ}</span>
            </p>
            <p>
              <strong>
                <span>Company</span>:
              </strong>
              <span> {uniqueCompanies.toString()}</span>
            </p>
            <p>
              <strong>
                <span>Type</span>:
              </strong>
              <span> {type}</span>
            </p>
            <p>
              <strong>
                <span>Preffered language</span>:
              </strong>
              <span> {language}</span>
            </p>
            {a1_docs[0] && (
              <p>
                <strong>
                  <span>A1 documents</span>:
                </strong>
                <span className="a1-doc"> </span>
              </p>
            )}
            {limosa_docs[0] && (
              <p>
                <strong>
                  <span>Limosa documents</span>:
                </strong>
                <span className="limosa-doc"> </span>
              </p>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default WorkerInfo;
