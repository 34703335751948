import { React, useEffect, useState } from "react";
import WorkerInfo from "./Worker/WorkerInfo";
import WorkersToolbox from "./Worker/WorkersToolbox";
import axios from "axios";
import "./WorkerDetailsPage.scss";
import { Row, Col, Spin } from "antd";
import { useParams, useLocation } from "react-router-dom";
import { useAuth } from "src/context/auth-context";

const WorkerDetailsPage = (props) => {
  const auth = useAuth();
  const location = useLocation();
  const { construction_site_id } = location.state;
  const { adminTitle } = location.state;
  const siteID = useParams();
  const url = `${process.env.REACT_APP_DRUPAL_URL}/jsonapi/worker_detail?name=${siteID.id}`;
  const [workersInfo, setWorkersInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    axios({
      url: url,
      method: "get",
      auth: {
        username: sessionStorage.getItem("username"),
        password: auth.pass,
      },
    })
      .then((workerInfoResponse) => {
        setWorkersInfo(workerInfoResponse.data);
        setIsLoading(true);
      })
      .catch((workerInfoError) => {
        console.log("workerInfoError", workerInfoError);
      });
    // eslint-disable-next-line
  }, [url]);
  return !isLoading ? (
    <Spin />
  ) : (
    <div className="worker-details-wrapper">
      <Row>
        <Col span={20} offset={2}>
          <h1>
            {workersInfo.firstname} {workersInfo.name}
          </h1>
        </Col>
      </Row>
      <Row>
        <Col span={20} offset={2} className="worker-info-toolbox-wrapper">
          <WorkerInfo
            siteID={siteID.id}
            data={workersInfo}
            construction_site_id={construction_site_id}
          />
          <WorkersToolbox
            data={workersInfo}
            siteID={siteID.id}
            adminTitle={adminTitle}
            construction_site_id={construction_site_id}
          />
        </Col>
      </Row>
    </div>
  );
};

export default WorkerDetailsPage;
