import { React, useState } from "react";
import axios from "axios";
import { Col, Row, message } from "antd";
import { Button, Form, Input } from "antd";
import { useAuth } from "src/context/auth-context";
import CryptoJS from "crypto-js";
import bannerImage from "../../assets/homepage-banner.png";
import "./HomePage.scss";

const Homepage = (props) => {
  const [loading, setLoading] = useState(false);
  const auth = useAuth();
  const onFinish = (values) => {
    setLoading(true);
    const userName = values.username;
    const passWord = values.password;

    const error = () => {
      setLoading(false);
      message.error("Invalid Username/Password");
    };

    axios
      .get(`${process.env.REACT_APP_DRUPAL_URL}/session/token`)
      .then((res) => {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_DRUPAL_URL}/user/login?_format=json`,
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": res.data,
          },
          data: {
            name: userName,
            pass: passWord,
          },
        })
          .then((response) => {
            const user_id = response.data.current_user.uid;

            axios
              .get(`${process.env.REACT_APP_DRUPAL_URL}/rest/user/${user_id}`)
              .then((roleResponse) => {

                if (roleResponse.data[0].roles_target_id === "Supervisor") {
                  // Encrypt
                  var ciphertext = CryptoJS.AES.encrypt(
                    passWord,
                    `${process.env.REACT_APP_SECRET_KEY}`
                  ).toString();
                  sessionStorage.setItem("username", userName);
                  sessionStorage.setItem("cipher", ciphertext);
                  sessionStorage.setItem("email", roleResponse.data[0].mail);
                  sessionStorage.setItem("user_id", user_id);
                  auth.login();
                } else {
                  error();
                }
              })
              .catch((roleResponseError) => {
                console.log("roleResponseError", roleResponseError);
              });
          })
          .catch((err) => {
            console.log("Error", err);
            error();
          });
      })
      .catch((err) => {
        console.warn(err);
      });
      
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={12} className="homepage-banner">
        <img src={bannerImage} alt="homepage-banner" />
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} className="form-wrapper">
        <div>
          <h1>Login to account</h1>
          <span>Please enter your email id and password to continue</span>
          <Form
            name="basic"
            layout="vertical"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="on"
          >
            <Form.Item
              label="Email Address"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your username!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password autoComplete="true" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" block loading={loading}>
                Sign In
              </Button>
            </Form.Item>
            <p className="forgot-password">
              <a
                href="https://admin.toolboxes.kaizer.be/user/password"
                target="_blank"
                rel="noreferrer"
              >
                Forgot Password?
              </a>
            </p>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default Homepage;
